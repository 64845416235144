@import "styles/variables.scss";

.header {
  display: flex;
  align-items: center;
  z-index: $z-index-header;
  position: relative;
}

.content {
  @include container;

  position: relative;
  height: $height-header;
  flex: 1;
  display: flex;
  align-items: center;
}

.items {
  flex: 1;
  display: flex;
  column-gap: $spacing-xxxl;
  margin: 0 64px; // one off spacing
}

@mixin item-text() {
  @include body();

  font-size: 15px;
  font-weight: $body-medium;
  line-height: 1.07;
  letter-spacing: 0.25px;
}

@mixin submenu-hover() {
  margin: -$spacing-xs;
  padding: $spacing-xs;
  transition-property: background-color;
  transition-duration: $transition-duration-hover;
  border-radius: $border-radius-control;

  &:hover {
    background-color: $greyscale-50;
  }
}

.item {
  @include item-text();
  @include link();

  padding: $spacing-xs $spacing-xxs;
  font-size: 15px;
  font-weight: $body-medium;
  line-height: 1.07;
  letter-spacing: 0.25px;
  color: $greyscale-800;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.button {
  @include item-text();

  height: 36px;
}

.angle {
  width: 16px;
  height: 16px;
  transition: transform 200ms;
  margin-left: $spacing-xs;
  transform: rotate(180deg);
  display: inline-block;
}

.active {
  color: $primary-400;

  .angle {
    transform: rotate(0deg);
  }
}

.actions {
  display: flex;
  align-items: center;
  column-gap: $spacing-m;
}

@mixin header-transition() {
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}

.position {
  @include header-transition();

  transition-property: all;
  position: relative;
}

.dropdownContent {
  @include header-transition();

  transition-property: opacity, transform;
  position: absolute;
  top: 0;
  opacity: 0;
  pointer-events: none;

  &.before {
    transform: translateX(100px);
  }

  &.after {
    transform: translateX(-100px);
  }

  &.active {
    opacity: 1;
    pointer-events: unset;
    transform: translateX(0);
  }
}

.dropdown {
  @include header-transition();

  transition-property: all;
  background: $white;
  box-shadow: $box-shadow-popover;
  border-radius: $border-radius-control;
  overflow: hidden;
  position: absolute;
  top: 80px;
  z-index: $z-index-popover;

  &.hidden {
    opacity: 0;
    pointer-events: none;
    transform: perspective(1000px) rotateX(-15deg);
  }

  &.fresh {
    transition-property: opacity, transform;

    .position,
    .dropdownContent {
      transition-property: none;
    }
  }
}

.sections {
  padding: $spacing-xl;
  display: flex;
  column-gap: $spacing-xxxxl;
  border-bottom: $greyscale-200;
}

.section {
  flex: 1;
}

.sectionLabel {
  @include body-xs();

  text-transform: uppercase;
  font-weight: $body-bold;
  line-height: 1.25;
  letter-spacing: 1px;
  text-align: left;
  color: $greyscale-500;
}

.sectionItems {
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;
}

.sectionItem {
  @include submenu-hover();

  display: flex;
  align-items: center;
}

.sectionItemContent {
  padding-left: $spacing-m;
}

.sectionItemLabel {
  @include body();

  color: $greyscale-800;
  font-size: 15px;
  font-weight: $body-medium;
  margin-bottom: $spacing-xxs;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sectionItemDescription {
  @include body-s();

  color: $greyscale-600;
}

.sectionItemTag {
  @include body();

  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 2.4;
  letter-spacing: 0.1px;
  color: $primary-400;
  font-weight: $body-bold;
  background-color: $primary-50;
  height: 20px;
  padding: 0 $spacing-xxs;
  border-radius: $border-radius-control;
}

.subitems {
  background-color: $greyscale-50;
  padding: $spacing-s $spacing-xl;
  display: flex;
  align-items: center;
  column-gap: $spacing-m;
  // New stacking context
  position: relative;
  z-index: 1;

  // Pseudo element with the same background to expand beyond the container so that
  // the background doesn't cut off where the end of the box will be while the box
  // is still animating down to size
  &::after {
    content: "";
    background-color: $greyscale-50;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    height: 400px;
  }
}

.subitem {
  @include body-s();
  @include link();

  font-weight: $body-medium;
  color: $greyscale-700;
}

.dot {
  width: 8px;
  height: 8px;
  color: $greyscale-400;
}

.companyItems {
  padding: $spacing-xl;
  display: flex;
  flex-direction: column;
  gap: $spacing-m;
}

.companyItem {
  @include submenu-hover();

  display: flex;
}

.companyItemContent {
  padding-left: $spacing-m;
}

.companyItemLabel {
  @include body();

  font-size: 15px;
  font-weight: $body-medium;
  line-height: 1.6;
  color: $greyscale-800;
}

.companyItemDescription {
  @include body-s();

  color: $greyscale-600;
}

.mobileMenu {
  display: none;
  margin-left: auto;
}

.mobileDropdown {
  @include header-transition();

  transition-property: opacity, transform;
  display: flex;
  position: fixed;
  z-index: $z-index-modal;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: $white;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;

  &.dropdownHidden {
    opacity: 0;
    pointer-events: none;
    transform: perspective(1000px) rotateX(-15deg);
    overflow-y: hidden;
  }

  .mobileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $spacing-m;
    min-height: $height-header;
  }

  .close {
    @include link;

    display: block;
    font-size: 22px;
    color: $greyscale-600;
  }

  .links {
    padding: $spacing-s $spacing-m;
  }

  .footer {
    flex: 1;
    padding: $spacing-m;
    background-color: $greyscale-50;
    border-top: $border-divider;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-m;

    .button {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }
  }

  .heading {
    @include body-s;

    text-transform: uppercase;
    line-height: 1.25;
    letter-spacing: 1px;
    color: $greyscale-500;
    font-weight: $body-bold;
    border-bottom: $border-divider;
    padding-bottom: $spacing-xs;
  }

  .main {
    padding: $spacing-s 0 $spacing-xl;
  }

  .largeItems {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;
  }

  .smallItems {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-s;
  }

  .link {
    @include submenu-hover();

    display: flex;
    align-items: center;
  }

  .detail {
    padding-left: $spacing-xs;
  }

  .label {
    @include body-s;

    font-weight: $body-bold;
    color: $greyscale-800;
  }

  .description {
    @include body-xs;

    color: $greyscale-600;
  }
}

.dark {
  .item {
    color: $greyscale-50;

    &:hover {
      color: $white;
    }
  }
}

// Mobiles styles defined separately here since they're much more different than a
// smooth responsive breakdown
.more {
  display: none;
}

@media screen and (max-width: 1000px) {
  .extra {
    display: none;
  }

  .more {
    display: flex;
  }
}

@media screen and (max-width: 840px) {
  .mobileMenu {
    display: block;

    .item {
      font-size: 20px;
    }
  }

  .actions,
  .items {
    display: none;
  }
}

/* stylelint-disable */
:export {
  // Use in the component for knowing when the animation has completed
  transitionDuration: $transition-duration-hover;
}
/* stylelint-enable */
