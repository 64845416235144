@import "styles/variables.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: $spacing-l $spacing-xxl;
  box-shadow: $box-shadow-content;
  border: 1px solid $greyscale-100;
  border-radius: $border-radius-content;
  background-color: $white;
  position: relative;
  z-index: $z-index-content;
  gap: $spacing-s;

  @media screen and (max-width: 700px) {
    padding: $spacing-m $spacing-l;
    align-items: flex-start;
    flex-direction: column;
  }
}

.content {
  flex: 1;
}

.heading {
  @include heading-xxxs;

  color: $greyscale-900;
  font-weight: $body-normal;
  margin-bottom: $spacing-xxs;
}

.description {
  @include body;

  font-size: 15px;
  line-height: 1.6;
  color: $greyscale-500;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-s;
}

.button {
  @include body-s;

  height: 40px;
}

.dark {
  background-color: $greyscale-900;
  border: 1px solid $greyscale-700;
  box-shadow: none;

  .heading {
    color: $greyscale-50;
  }

  .description {
    color: $greyscale-200;
  }
}
