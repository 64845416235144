@import "styles/variables.scss";

.divider {
  position: absolute;
  width: 100%;
  overflow: hidden;
  line-height: 0;

  > svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
  }
}

.rotate {
  transform: rotate(180deg);
}

.flip {
  > svg {
    transform: rotateY(180deg);
  }
}
