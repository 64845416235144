@import 'styles/variables.scss';

.container {
  display: block;

  .text {
    transition-property: fill;
    transition-duration: $transition-duration-hover;
  }

  .paw {
    transition-property: fill;
    transition-duration: $transition-duration-hover;
  }
}

@mixin logo($text-color, $paw-color, $hover-color) {
  .text {
    fill: $text-color;
  }

  .paw {
    fill: $paw-color;
  }

  &:hover {
    .text {
      fill: $hover-color;
    }

    .paw {
      fill: $hover-color;
    }
  }
}

.default {
  @include logo($greyscale-500, $primary-400, $primary-400);
}

.white {
  @include logo($greyscale-50, $greyscale-50, $white);
}

.grey {
  @include logo($greyscale-400, $greyscale-400, $primary-400);
}
