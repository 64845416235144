@import "styles/variables.scss";

.arrow {
  transition: transform $transition-duration-hover $transition-timing-function-hover;
  font-size: 18px;
  margin-left: $spacing-xs;
  transform: translateX(-3px);
}

.button {
  @include body-m;

  border-radius: 26px;
  padding: $spacing-s $spacing-m;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-xs;
  box-shadow: $box-shadow-control;
  transition: all $transition-duration-hover $transition-timing-function-hover;
  white-space: nowrap;

  &:hover {
    .arrow {
      transform: translateX(0);
    }
  }
}

.primary {
  background-color: $primary-500;
  color: $greyscale-50;
  font-weight: $body-bold;

  &:hover {
    background-color: $primary-400;
  }
}

.secondary {
  background-color: $white;
  color: $greyscale-900;
  border: 1px solid $greyscale-200;
  font-weight: $body-medium;

  &:hover {
    background-color: $greyscale-100;
  }
}
