@import 'styles/variables.scss';

.container {
  z-index: $z-index-modal;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: fit-content;
  width: 100%;
  padding: $spacing-m;
}

.background {
  background-color: rgba($greyscale-500, 0.2);
  transition-property: opacity;
  transition-duration: 300ms;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  background-color: $greyscale-900;
  box-shadow: 0 20px 24px 0 rgba(153, 155, 168, 0.18);
  display: flex;
  flex-direction: column;
  transition-property: all;
  transition-duration: 300ms;
  border-radius: 8px;

  &:focus {
    outline: none;
  }
}

.close {
  @include body-s;
  @include link;

  font-weight: $body-medium;
  display: flex;
  align-items: center;
  position: absolute;
  color: $greyscale-600;
  top: -$spacing-m;
  right: 0;
  transform: translateY(-100%);
}

.icon {
  font-size: 18px;
  margin-left: $spacing-xs;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;

  // Set height to 16:9 aspect ratio (56.25%) of the width
  &::before {
    display: block;
    content: '';
    padding-top: 56.25%;
  }
}

// Fill space of content
.video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.hidden {
  pointer-events: none;

  .background {
    opacity: 0;
  }

  .modal {
    transform: scale(0.8);
    opacity: 0;
  }
}

.visible {
  .background {
    opacity: 1;
  }

  .modal {
    transform: scale(1);
    opacity: 1;
  }
}
