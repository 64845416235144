@import "styles/variables.scss";

.container {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    gap: $spacing-xl;
  }

  .label {
    @include body-label;

    color: $greyscale-600;
  }

  .heading {
    @include heading-xs;

    color: $greyscale-900;
    margin-top: $spacing-xs;
  }

  .content {
    flex: 1;
  }

  .cards {
    flex: 2;
    display: flex;
    gap: $spacing-xl;

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    .cards {
      margin-top: $spacing-xxl;
    }
  }

  @media screen and (max-width: 600px) {
    .cards {
      flex-direction: column;
    }
  }
}

.card {
  flex: 1;
  padding: $spacing-xl;
  background-color: $white;
  border-radius: $border-radius-content;
  box-shadow: $box-shadow-container;
  border: $border-box;
  position: relative;
  overflow: hidden;

  .hexagon {
    position: absolute;
    // Pixel push to create background
    top: -30px;
    left: -20px;
  }

  .hexIcon {
    font-size: 56px;
  }

  .heading {
    @include body-l;

    color: $greyscale-900;
    font-weight: $body-medium;
    margin-top: 70px;
  }

  .description {
    @include body-s;

    color: $greyscale-600;
    margin-top: $spacing-s;
  }

  .link {
    @include link(color);
    @include body-m;

    font-weight: $body-medium;
    display: flex;
    align-items: center;
    gap: $spacing-xxxs;
    margin-top: $spacing-l;
    color: $primary-500;
  }
}
