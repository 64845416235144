@import "styles/variables.scss";

.container {
  @include container;

  position: relative;
}

.label {
  @include body-label;

  color: $greyscale-600;
}

.sectionOne {
  background-color: $primary-500;
  margin-top: -$height-header;
  padding-top: $height-header;
  position: relative;
  padding-bottom: 200px;
  overflow: hidden;

  .divider,
  .mobileDivider {
    position: absolute;
    bottom: 0;
    color: $primary-50;
  }

  .hexes {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    min-width: 1440px;
    background-image: linear-gradient(to left, $primary-600, $transparent 20%, $primary-600);
  }

  .mobileBg,
  .mobileDivider {
    display: none;
  }

  .container {
    position: relative;
    z-index: $z-index-content;
  }

  @media screen and (max-width: 700px) {
    padding-bottom: 125px;

    .hexes,
    .overlay,
    .divider {
      display: none;
    }

    .mobileBg,
    .mobileDivider {
      display: block;
    }

    .mobileBg {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.sectionTwo {
  background-image: linear-gradient(to bottom, $primary-50, $greyscale-50 60%);
  position: relative;
  padding-bottom: 100px;

  .hex {
    position: absolute;
    top: 100px;
    left: 100px;
  }

  .hexes {
    position: absolute;
    top: 200px;
    right: -100px;
  }

  @media screen and (max-width: 800px) {
    .hex,
    .hexes {
      display: none;
    }
  }
}

.sectionThree {
  position: relative;
  padding-top: 200px;
  padding-bottom: 140px;

  .dividerTop {
    position: absolute;
    top: 0;
    color: $greyscale-50;
  }

  .dividerBottom {
    position: absolute;
    bottom: 0;
    color: $greyscale-50;
  }

  .mobileDividerTop,
  .mobileDividerBottom {
    display: none;
    position: absolute;
    color: $greyscale-50;
  }

  .hex {
    position: absolute;
    top: 400px;
    right: 200px;
    transform: rotateY(180deg);
  }

  .hexes {
    position: absolute;
    top: 300px;
    left: -200px;
  }

  background-image: linear-gradient(146deg, $greyscale-100 9%, rgba(255, 255, 255, 0) 100%);

  @media screen and (max-width: 1100px) {
    .hex,
    .hexes {
      display: none;
    }
  }

  @media screen and (max-width: 700px) {
    padding-top: 120px;
    padding-bottom: 90px;

    .dividerBottom,
    .dividerTop {
      display: none;
    }

    .mobileDividerTop {
      display: block;
      top: 0;
    }

    .mobileDividerBottom {
      display: block;
      bottom: 0;
    }
  }
}

.sectionFour {
  background-image: linear-gradient(to bottom, $greyscale-50, rgba(255, 255, 255, 0) 50%);
  padding-top: 100px;
}

.banner {
  text-align: center;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-xs;
  padding: $spacing-xxs;

  .tag {
    @include body-xs;

    text-transform: uppercase;
    height: 24px;
    padding: 0 $spacing-s;
    border-radius: 12px;
    color: $primary-500;
    font-weight: $body-medium;
    letter-spacing: 0.2px;
    line-height: 1.5;
    background-color: $primary-50;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text {
    @include body-xs;

    color: $greyscale-700;
    font-weight: $body-medium;
  }

  .link {
    @include body-xs;
    @include link;

    color: $primary-500;
    font-weight: $body-medium;
  }
}

.hero {
  display: flex;
  margin-top: 40px;

  .content {
    flex: 1;
    min-width: 700px;
    margin-top: 60px;
  }

  .highlight {
    position: relative;
    display: inline-block;
  }

  .underline {
    position: absolute;
    left: 28px;
    top: 28px;
  }

  .heading {
    @include heading-xl;

    color: $greyscale-50;
    margin-bottom: $spacing-xxl;
  }

  .description {
    @include body-xl;

    color: $greyscale-100;
    margin-bottom: $spacing-xxl;
    max-width: 560px;
  }

  .actions {
    display: flex;
  }

  .imageContent {
    position: relative;
    flex: 1;
  }

  .image {
    position: absolute;
    left: 20px;
  }

  @media screen and (max-width: 1100px) {
    .content {
      min-width: 500px;
    }
  }

  @media screen and (max-width: 700px) {
    margin-top: 56px;

    .content {
      min-width: 0;
      margin-top: 0;
    }

    .heading {
      @include heading-m;
    }

    .description {
      @include body-l;
    }

    .imageContent {
      display: none;
    }
  }
}

.organizations {
  border: $border-box;
  background-color: $white;
  border-radius: $border-radius-content;
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.03);
  position: relative;
  transform: translateY(-48px);

  .label {
    color: $greyscale-500;
    text-align: center;
    padding-top: $spacing-l;
    margin-bottom: -$spacing-xxxl;
  }

  .logos {
    display: flex;
    padding: 0 80px;
    justify-content: space-between;
    align-items: flex-end;
  }

  .logo {
    margin-bottom: $spacing-xxl;
    padding: 0 $spacing-xs;
  }

  .mobileLine {
    display: none;
  }

  @media screen and (max-width: 600px) {
    $border-line-spacing: 9px; // $spacing-xs accounting for 2px line

    .label {
      @include body-xs;

      font-weight: $body-medium;
      margin-bottom: $border-line-spacing;
    }

    .logos {
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      position: relative;
      padding: $spacing-s $spacing-xxl $spacing-l;
    }

    .logo {
      margin-bottom: 0;
      max-width: 120px;
    }

    .border {
      height: 3px;
      width: 140px;
    }

    .line {
      display: none;
    }

    .mobileLine {
      display: block;
      position: absolute;
      width: 75%;
      border-top: 2px dashed $greyscale-200;
      content: "";
    }

    & > .mobileLine {
      &:first-of-type {
        top: $border-line-spacing;
        right: 0;
      }

      &:last-of-type {
        bottom: $border-line-spacing;
        right: 0;
      }
    }

    .logos > .mobileLine {
      left: 0;
      top: 0;
    }

    .lineHighlight {
      position: absolute;
      top: -2px;
      left: 50%;
      transform: translateX(-50%);
      width: 23px;
      height: 2px;
      background-color: $primary-500;
    }
  }
}

.procurement {
  padding-top: 112px;

  .label {
    background-color: $greyscale-100;
    color: $greyscale-600;
    border-radius: 25px;
    display: inline-block;
    padding: $spacing-xxs $spacing-m;
  }

  .content {
    display: flex;
    gap: $spacing-l;
    margin-top: $spacing-xxxl;
  }

  .icon {
    font-size: 28px;
  }

  .heading {
    @include heading-s;

    color: $greyscale-900;
    margin-bottom: $spacing-xl;

    @media screen and (max-width: $mobile-breakpoint) {
      @include heading-xs;
    }
  }

  .description {
    @include body-l;

    color: $greyscale-600;
    max-width: 570px;

    @media screen and (max-width: $mobile-breakpoint) {
      @include body-m;
    }
  }

  .image {
    margin-top: 64px;
    margin-left: -24px; // pixel push to alignment with content text
  }

  .actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  @media screen and (max-width: 700px) {
    padding-top: 89px;
    text-align: center;

    .content {
      flex-direction: column;
      align-items: center;
    }

    .icon {
      display: none;
    }
  }
}

.pharmacy {
  .label {
    background-color: adjust-color($color: $white, $alpha: -0.3);
    color: $greyscale-600;
    border-radius: 25px;
    display: inline-block;
    padding: $spacing-xxs $spacing-m;
  }

  .content {
    display: flex;
    gap: $spacing-l;
    margin-top: $spacing-xxxl;
  }

  .icon {
    font-size: 28px;
  }

  .heading {
    @include heading-s;

    color: $greyscale-900;
    margin-bottom: $spacing-xl;

    @media screen and (max-width: $mobile-breakpoint) {
      @include heading-xs;
    }
  }

  .description {
    @include body-l;

    color: $greyscale-600;
    max-width: 570px;

    @media screen and (max-width: $mobile-breakpoint) {
      @include body-m;
    }
  }

  .image {
    margin-top: 64px;
    margin-left: -24px; // pixel push to alignment with content text
  }

  .actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  @media screen and (max-width: 700px) {
    text-align: center;

    .content {
      flex-direction: column;
      align-items: center;
    }

    .icon {
      display: none;
    }
  }
}

.whosUsing {
  .highlight {
    color: $primary-500;
  }

  .content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .heading {
    @include heading-s;

    color: $greyscale-900;
    max-width: 800px;
    margin-top: $spacing-s;

    @media screen and (max-width: $mobile-breakpoint) {
      @include heading-xs;
    }
  }

  .playIcon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $primary-500;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $white;
  }

  .videoButton {
    @include body-m;

    display: flex;
    gap: $spacing-m;
    margin: $spacing-l auto;
    align-items: center;
    width: fit-content;
    text-align: left;
    transition: all $transition-duration-hover $transition-timing-function-hover;
    background: $white;
    border: 1px solid $greyscale-200;
    padding: $spacing-m $spacing-xxl;
    box-shadow: 0 7px 11px 0 rgba(50, 72, 93, 0.11), 0 4px 8px 0 rgba(0, 0, 0, 0);
    border-radius: 48px;

    &:hover {
      background: $greyscale-100;
    }
  }

  .buttonLabel {
    line-height: 1.5;
    color: $greyscale-600;
  }

  .buttonLink {
    line-height: 1.5;
    color: $primary-500;
    font-weight: $body-medium;
    transition: all $transition-duration-hover $transition-timing-function-hover;

    &:hover {
      color: $primary-600;
    }
  }

  @media screen and (max-width: 600px) {
    .videoButton {
      background: none;
      border: none;
      box-shadow: none;
    }
  }
}

.testimonials {
  position: relative;
  padding-top: 40px;

  .extra {
    @include handwriting;

    font-size: 18px;
    color: $primary-500;
    position: absolute;
    top: -54px;
    left: 8%;
    transform: rotate(-6deg);

    .arrow {
      position: absolute;
      right: 28px;
      top: 28px;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-content;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $white 90%);
  }

  .cards {
    display: block;
    overflow-x: hidden;
  }

  .topRow,
  .bottomRow {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: $spacing-m;
    padding-bottom: $spacing-m;
  }

  .bottomRow {
    transform: translateX(-6%);
  }

  .card {
    background-color: $white;
    min-width: 268px;
    flex: 0;
    padding: $spacing-m;
    border: 1px solid $greyscale-100;
    border-radius: $border-radius-content;
    box-shadow: $box-shadow-content;
    transition: all $transition-duration-hover $transition-timing-function-hover;
    position: relative;

    .top {
      display: flex;
      align-items: center;
    }

    .quote {
      @include heading;

      font-size: 42px;
      color: $primary-200;
      line-height: 1;
      height: 20px;
      margin-right: auto;
    }

    .type {
      @include body;

      font-size: 11px;
      font-weight: $body-medium;
      line-height: 1.5;
      text-transform: uppercase;
      color: $primary-500;
      background-color: $primary-50;
      padding: $spacing-xxxs $spacing-xs;
      border-radius: $border-radius-control;
      margin-left: auto;
    }

    .fadeOut {
      position: absolute;
      bottom: 0;
      height: 50px;
      width: 100%;
      background-image: linear-gradient(to bottom, $transparent, $white);
    }

    .reviewSection {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-bottom: $spacing-m;
    }

    .review {
      @include body;

      font-size: 13px;
      line-height: 1.5;
      color: $greyscale-600;
      margin-top: $spacing-s;
      overflow: hidden;
      position: relative;
      height: 180px;
    }

    .doctor {
      @include body;

      font-size: 13px;
      font-weight: $body-medium;
      line-height: 1.5;
      color: $greyscale-900;
    }

    .clinic {
      @include body;

      font-size: 11px;
      line-height: 1.5;
      color: $greyscale-400;
    }
  }

  @media screen and (max-width: 1050px) {
    padding-top: 80px;

    .extra {
      top: 0;
      left: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .extra {
      top: 0;
      left: -16px;
      transform: rotate(-6deg) scale(0.8);
    }

    .cards {
      margin-left: -60%; // Shift to keep one card fully in view
    }
  }
}

.learnMore {
  padding-top: 40px;
}
