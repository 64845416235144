@import 'styles/variables.scss';

.footer {
  position: relative;
  overflow: hidden;
  padding-top: 200px;

  .link {
    text-align: left;
  }
}

.cta {
  padding-top: 80px;
}

.content {
  @include container;

  position: relative;
  padding-bottom: $spacing-xxxxl;
}

.main {
  display: flex;
  justify-content: space-between;
  gap: $spacing-xxxxl;
  padding: 80px $spacing-xxl $spacing-xxxl 0;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.details {
  @include body-s;

  display: flex;
  justify-content: space-between;
  color: $greyscale-500;
  gap: $spacing-xxxl;
  margin-top: $spacing-xl;

  .links {
    display: flex;
    gap: $spacing-xxl;

    .link {
      @include link;
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
  }

  @media screen and (max-width: 600px) {
    .links {
      gap: $spacing-xxs;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: $spacing-m;

      .link {
        margin-bottom: $spacing-m;
      }
    }
  }
}

.categories {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $spacing-xxxxl;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  .category {
    display: flex;
    flex-direction: column;
    gap: $spacing-m;

    .title {
      @include body-m;

      color: $greyscale-700;
      font-weight: $body-medium;
    }

    .links {
      display: flex;
      flex-direction: column;
      gap: $spacing-s;

      .link {
        @include link;
        @include body-s;

        color: $greyscale-600;
      }
    }
  }
}

.company {
  min-width: 180px;

  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: space-between;
  }

  .slogan {
    @include body-s;

    color: $greyscale-600;
    margin-top: $spacing-s;
    margin-bottom: $spacing-xxl;
  }

  .socials {
    display: flex;
    gap: $spacing-m;
    font-size: 30px;
    color: $greyscale-400;

    .social {
      @include link;
    }
  }
}

.divider {
  background-color: $greyscale-200;
  height: 1px;
}

.hexagons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: $z-index-footer;
  background-repeat: no-repeat;
  background-position: center top;

  @media screen and (min-width: 1440px) {
    background-size: cover;
  }
}

.dark {
  background-color: $greyscale-900;
  padding-top: 100px;

  .categories {
    .category {
      .title {
        color: $white;
      }

      .links {
        .link {
          color: $greyscale-100;
        }
      }
    }
  }

  .company {
    .slogan {
      color: $greyscale-200;
    }

    .socials {
      color: $greyscale-300;
    }
  }

  .divider {
    background-color: $greyscale-600;
  }

  .details {
    color: $greyscale-300;
  }
}
