@import 'styles/variables.scss';

.arrow {
  transition: transform $transition-duration-hover $transition-timing-function-hover;
  margin-left: $spacing-xs;
  transform: translateX(-3px);
}

.container {
  &:hover {
    .arrow {
      transform: translateX(0);
    }
  }
}
