@use "sass:math";

/* COLORS - https://app.zeplin.io/project/61705aabf923669353b9a6e4/styleguide/colors */
$white: #fff;

// Dark mode browsers will render 'transparent' differently, hence using a white rgba instead
$transparent: adjust-color($color: $white, $alpha: -1);

$greyscale-50: #f7fafc;
$greyscale-100: #edf2f7;
$greyscale-200: #e2e8f0;
$greyscale-300: #cbd5e0;
$greyscale-400: #a0aec0;
$greyscale-500: #718096;
$greyscale-600: #535c6c;
$greyscale-700: #3a414e;
$greyscale-800: #272c36;
$greyscale-900: #1a202c;

$primary-50: #ebf8ff;
$primary-100: #dcf1fa;
$primary-200: #a4dbf3;
$primary-300: #82ceee;
$primary-400: #28a9e2;
$primary-500: #249ed2;
$primary-600: #2293c3;
$primary-700: #1b749a;
$primary-800: #10445a;
$primary-900: #092a37;

$success-50: #effff3;
$success-100: #d5fddf;
$success-200: #a7f0ba;
$success-300: #6fdc8c;
$success-400: #4ad15f;
$success-500: #2bb452;
$success-600: #198038;
$success-700: #0e6027;
$success-800: #044317;
$success-900: #022d0d;

$error-50: #fff1f1;
$error-100: #ffd7d9;
$error-200: #ffb3b8;
$error-300: #ff8389;
$error-400: #fa4d56;
$error-500: #fa4d4d;
$error-600: #da1e28;
$error-700: #a2191f;
$error-800: #750e13;
$error-900: #520408;

$warning-50: #fffbf4;
$warning-100: #fff7e8;
$warning-200: #ffd888;
$warning-300: #ffc56f;
$warning-400: #ffb038;
$warning-700: #d88912;
$warning-500: #ffa319;
$warning-600: #ef940b;
$warning-800: #a9751a;
$warning-900: #774d04;

/* FONTS - https://app.zeplin.io/project/61705aabf923669353b9a6e4/styleguide/textstyles */
$font-family-heading: "Lora", serif;
$font-family-body: "Inter", sans-serif;
$font-family-handwriting: "Architects Daughter", cursive;

$heading-normal: 500;
$heading-bold: 600;

$body-normal: 400;
$body-medium: 500;
$body-bold: 600;

@mixin heading() {
  font-family: $font-family-heading;
  font-weight: $heading-normal;

  strong {
    font-weight: $heading-bold;
  }
}

@mixin body() {
  font-family: $font-family-body;
  font-weight: $body-normal;

  strong {
    font-weight: $body-bold;
  }
}

@mixin handwriting() {
  font-family: $font-family-handwriting;
}

@mixin heading-xl {
  @include heading();

  font-size: 64px;
  line-height: 1.2;
  letter-spacing: -1.92px;
}

@mixin heading-l {
  @include heading();

  font-size: 52px;
  line-height: 1.12;
  letter-spacing: -1.04px;
}

@mixin heading-m {
  @include heading();

  font-size: 48px;
  line-height: 1.2;
  letter-spacing: -1px;
}

@mixin heading-s {
  @include heading();

  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -0.2px;
}

@mixin heading-xs {
  @include heading();

  font-size: 32px;
  line-height: 1.3;
  letter-spacing: -0.5px;
}

@mixin heading-xxs {
  @include heading();

  font-size: 28px;
  line-height: 1.25;
  letter-spacing: -0.5px;
}

@mixin heading-xxxs {
  @include heading();

  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.5px;
}

@mixin body-xl {
  @include body();

  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.2px;
}

@mixin body-l {
  @include body();

  font-size: 18px;
  line-height: 1.6;
}

@mixin body-m {
  @include body();

  font-size: 16px;
  line-height: 1.6;
}

@mixin body-s {
  @include body();

  font-size: 14px;
  line-height: 1.6;
}

@mixin body-xs {
  @include body();

  font-size: 12px;
  line-height: 1.5;
}

@mixin body-label {
  @include body();

  font-size: 14px;
  font-weight: $body-medium;
  line-height: 2.36;
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

/* SPACING - https://app.zeplin.io/project/61705aabf923669353b9a6e4/styleguide/spacing */
$spacing-xxxs: 2px;
$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 12px;
$spacing-m: 16px;
$spacing-l: 20px;
$spacing-xl: 24px;
$spacing-xxl: 32px;
$spacing-xxxl: 40px;
$spacing-xxxxl: 48px;

/* WIDTHS */
$width-page: 1440px;
$width-container: 1120px;
$width-page-margin: math.div($width-page - $width-container, 2);

/* HEIGHTS */
$height-header: 80px;

/* BREAKPOINTS */
$mobile-breakpoint: 500px;

/* TRANSITIONS */
$transition-duration-hover: 150ms;
$transition-timing-function-hover: ease-in-out;

/* BOX SHADOWS */
$box-shadow-control: 0 2px 4px 0 rgba(35, 38, 45, 0.09);
$box-shadow-container: 0 10px 35px 0 rgba(0, 0, 0, 0.03);
$box-shadow-popover: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
$box-shadow-content: 0 2px 11px 0 $greyscale-100;

/* INTERACTIVE */
@mixin link($color-prop: color) {
  transition-property: $color-prop;
  transition-duration: $transition-duration-hover;

  &:not(:disabled):hover {
    #{$color-prop}: $primary-400;
  }
}

/* BORDERS */
$border-divider: 1px solid $greyscale-100;
$border-box: 1px solid $greyscale-100;
$border-radius-control: 4px;
$border-radius-content: 8px;

@mixin border-left-gradient() {
  border-left-style: solid;
  border-left-width: 1px;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-image-source: linear-gradient(to bottom, $transparent, $greyscale-200);
  border-image-slice: 1;
}

/* Z-INDEX */
$z-index-footer: -2;
$z-index-background: -1;
$z-index-content: 1;
$z-index-header: 2;
$z-index-overlay: 3;
$z-index-popover: 10;
$z-index-chat: 99;
$z-index-modal: 100;

/* LAYOUT */
@mixin container {
  $padding: $spacing-m;

  margin: 0 auto;
  padding: 0 $padding;
  max-width: $width-container + ($padding * 2);
}
