@import 'styles/variables.scss';

.container {
  position: relative;
  flex-shrink: 0;
}

.content {
  @include body;

  font-size: 20px;
  line-height: 1;
  font-weight: $body-medium;
  color: $primary-500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rotated {
  transform: rotate(30deg);
}
